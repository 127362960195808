<template>
	<div class="navmb">
		<div class="flex flexa">
			<span class="">知识库中心</span>
		</div>
	</div>

	<p class="h20"></p>
	<div class="m-mainbox clearfix">
		<div class="col-l">
			<!-- 知识库文档 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">知识库文档</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/knowledge/docSearch'
					})">
						<div class="more">查看更多</div>
					</div>
				</div>
				<ul class="g-tabsb1">
					<li :class="{ active: s_doclist.type == 3 }" @click="s_doclist.type = 3">
						<div class="con">搜索和浏览</div>
					</li>
					<li :class="{ active: s_doclist.type == 1 }" @click="getDocListByType(1)">
						<div class="con">最新更新</div>
					</li>
					<li :class="{ active: s_doclist.type == 2 }" @click="getDocListByType(2)">
						<div class="con">最近查看</div>
					</li>
					<li :class="{ active: s_doclist.type == 4 }" @click="getDocListByCollect(4)">
						<div class="con">收藏的文章</div>
					</li>
				</ul>

				<div class="m-chobox" v-loading="s_doclist.loading">
					<template v-if="s_doclist.type == 3">
						<div class="line">
							<div class="type">选择所属分类和文档分类</div>
							<div class="gp df">
								<div class="item">
									<el-cascader
										v-model="search.categoryId"
										class="w100"
										size="large"
										collapse-tags 
										:props="s_doclist.props"
										:options="s_doclist.options"
										clearable
										placeholder="选择文档所属分类"
									/>
								</div>
								<div class="item">
									<el-select size="large" v-model="search.docType" placeholder="选择文档类型">
										<el-option label="知识文档" :value="1" />
										<el-option label="Bug文档" :value="2" />
									</el-select>
								</div>
							</div>
						</div>
						<div class="line">
							<div class="type">输入关键词搜索</div>

							<el-input size="large" v-model="search.docTitle" placeholder="输入文档的名称、文档描述等"
								@keydown.enter="$router.push({
									path: '/knowledge/docSearch',
									state: {
										docTitle: search.docTitle,
										docType: search.docType,
										categoryId: search.categoryId?search.categoryId.join(','):''
									}
								})"
							>
								<template #suffix>
									<el-icon color="#FF6A00" class="point" @click="$router.push({
										path: '/knowledge/docSearch',
										state: {
											docTitle: search.docTitle,
											docType: search.docType,
											categoryId:search.categoryId?search.categoryId.join(','):''
										}
									})">
										<Search />
									</el-icon>
								</template>
							</el-input>
						</div>
						<div class="line">
							<div class="type">搜索历史</div>
							<ul class="ul-hisb1">
								<li
									v-for="(item, index) in searchList"
									:key="index"
								>
									<div class="a" @click="$router.push({
										path: '/knowledge/docSearch',
										state: {
											docTitle: item,
											docType: search.docType,
											categoryId: search.categoryId,
										}
									})">{{ item }}</div>
								</li>
								<li v-if="$store.getters['searchStore/get_search'].length" class="hisitem" @click="$store.commit('searchStore/clearSearch')">
									<div style="color: #337eff" class="a">清除历史</div>
								</li>
							</ul>
						</div>
					</template>
					<template v-else-if="s_doclist.type == 4">
						<el-scrollbar height="400px" noresize>
							<div v-for="(item, index) in s_doclist_maplist" :key="index">
								<div class="flex flexsb marb10">
									<div class="flex flexc lh24 marb10">
										<el-icon @click="clickDelete(item.id)" color="#ff6a00" size="16" class="marr20">
											<StarFilled />
										</el-icon>
										<p class="colHtext" @click="$router.push('/knowledgeInfo/'+item.id)" type="primary">{{ item.docTitle }}{{item.docNumber?`(${item.docNumber})`:''}}</p>
									</div>
									<p class="col9c">{{ item.createTime }}</p>
								</div>
							</div>
						</el-scrollbar>
					</template>
					<template v-else-if="s_doclist.type == 1">
						<el-scrollbar height="400px" noresize>
							<div v-for="(item, index) in s_doclist_maplist" :key="index">
								<div class="font12 lh20 marb20">{{ item.dayStr }}</div>
								<ul class="font12 lh20">
									<li class="flex flexa lh24 marb10" v-for="(_item, _index) in item.docListDtos" :key="_index">
										<p class="col9c marr20">{{ _item.createTime }}</p>
										<p class="colHtext" @click="$router.push('/knowledgeInfo/'+_item.id)" type="primary">{{ _item.docTitle }}{{_item.docNumber?`(${_item.docNumber})`:''}}</p>
									</li>
								</ul>
							</div>
						</el-scrollbar>
					</template>
					<template v-else-if="s_doclist.type == 2">
						<el-scrollbar height="400px" noresize>
							<div v-for="(item, index) in s_doclist_maplist" :key="index">
								<div class="font12 lh20 marb20">{{ item.dayStr }}</div>
								<ul class="font12 lh20">
									<li class="flex flexa lh24 marb10" v-for="(_item, _index) in item.docListDtos" :key="_index">
										<p class="col9c marr20">{{ _item.createTime }}</p>
										<p class="colHtext" @click="$router.push('/knowledgeInfo/'+_item.id)" type="primary">{{ _item.docTitle }}{{_item.docNumber?`(${_item.docNumber})`:''}}</p>
									</li>
								</ul>
							</div>
						</el-scrollbar>
					</template>
				</div>
			</div>
			<!--服务请求 -->
			<div class="g-boxb1">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">知识库文档推荐</div>
					</div>
					<!-- <div class="right" @click="$router.push({
						path: '/knowledge/docSearch'
					})">
						<div class="more">查看更多</div>
					</div> -->
				</div>
				<ul class="ul-docls">
					<li v-for="(item, index) in docList_t" @click="$router.push({
						path: '/knowledge/docSearch',
						state: {
							categoryId: item.docCategoryId,
						}
					})" :key="index">
						<div class="con">
							<div class="tit">
								{{ item.docCategory }}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-r">
			<!-- 我的文档 -->
			<div class="g-boxb1" style="padding-bottom: 26px;">
				<div class="g-titb1">
					<div class="left">
						<div class="h3">我的文档</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/document/my'
					})">
						<div class="more">查看全部</div>
					</div>
				</div>
				<ul class="ul-listb1">
					<li>
						<div class="con" @click="$router.push({
						path: '/document/my',
					})">
							<div class="p">全部数量</div>
							<div class="num">{{ docStatus.docTotal ?? '-' }}</div>
						</div>
					</li>
					<li>
						<div class="con" @click="$router.push({
						path: '/document/my',
						state: {
							isCompany: '1',
						}
					})">
							<div class="p">同步公司</div>
							<div class="num">{{ docStatus.companyCount ?? '-' }}</div>
						</div>
					</li>
					<li>
						<div class="con" @click="$router.push({
						path: '/document/my',
						state: {
							isSystem: '1',
						}
					})">
							<div class="p">平台采纳</div>
							<div class="num">{{ docStatus.systemCount ?? '-' }}</div>
						</div>
					</li>
				</ul>
				<div class="flex">
					<div style="width:66.6%;margin-right: 13px">
						<router-link to="/document/create" class="doc-btn" tag="li" draggable="false">
							<el-icon size="25" color="#fff">
								<Edit />
							</el-icon><span>写文章</span>
						</router-link>
					</div>

					<div class="con flex flexa flexc"
						style="display:flex;width:33.3%;flex:1;background-color: #f7f7f7;" @click="$router.push({
						path: '/document/type'
					})">
						<div class="tc flex flexa flexc font12 col68" style="margin-bottom: 0;">
							<img :src="utils.loadImg('type.png')" width="16" />
							<div>分类管理</div>
						</div>
					</div>
				</div>

			</div>
			<!-- 我的收藏 -->
			<div class="g-boxb1">
				<div class="g-titb1" style="margin-bottom: 6px;">
					<div class="left">
						<div class="h3">公司文档</div>
					</div>
					<div class="right" @click="$router.push({
						path: '/knowledge/companyDoc',
						query: {
							id: docList.param.companyId
						}
				 })">
						<div class="more">查看更多</div>
					</div>
				</div>
				<div class="w240">
					<el-select size="large"
						v-model="docList.param.companyId"
						placeholder="选择所属公司"
						@change="getDocList"
					>
						<el-option
							v-for="item in companyList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</div>
				<div class="h20"></div>
				<div class="tabbox">
					<div class="scrboxb1" v-loading="docList.loading">
						<ul class="ul-listb2" v-if="docList.list.length">
							<li
								v-for="(item, index) in docList.list"
								:key="index"
								@click="$router.push({
									name: 'knowledgeInfo',
									params: {
										id: item.id
									}
								})"
							>
								<div class="con">
									<div class="tit">{{ item.docTitle }}</div>
									<div class="date">{{ item.createTime }}</div>
								</div>
							</li>
						</ul>
						<div v-else></div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
//图标
import { mapGetters } from 'vuex'
import { Search, Edit, StarFilled } from '@element-plus/icons-vue'
export default {
	components: {
		Search, Edit, StarFilled
	},
	computed: {
		...mapGetters({
			searchList: 'searchStore/get_search',
		}),
		s_doclist_maplist() {
			return this.s_doclist.mapList.get(this.s_doclist.type) ?? []
		}
	},
	data() {
		return {
			search: {
				categoryId: '',
				docType: '',
				docTitle: ''
			},
			docStatus: {},
			docTab: 1,// 知识库文档tab

			docList_t: [],
			s_doclist: {
				type: '3',
				mapList: new Map(),
				loading: false,
				props: {
					value: 'id',
                        multiple: true, 
					label: 'categoryTitle',
					children: 'list',
                    checkStrictly: false,
                    emitPath: false,
                },
                options: [],
			},
			companyList: [],
			docList: {
				param: {
					companyId: '',
					pageNumber: 1,
					pageSize: 100
				},
				list: [],
				loading: false,
			},
		}
	},
	mounted() {
		this.getDocStatusCount()
		this.docCateList()
		this.userCompanyLists()
		this.getDocList_t()
	},
	methods: {
		clickDelete(id) {
			// this.$api.know.collectDoc({id}).then(res=>{
			// 	console.log('取消收藏返回',res)
			// })
		},
		getDocList_t(){
			this.$api.know.knowledgeBaseRecommendations({
				pageNumber: 1,
				pageSize: 100
			}).then(res=>{
				if(res){
					this.docList_t = res.records
				}
			})
		},
		async userCompanyLists() {
			try {
				const result = await this.$api.document.userCompanyLists()
				this.companyList = result
				if (result.length) {
					this.docList.param.companyId = result[0]?.id??''
					this.getDocList(result[0].id)
				}
			} catch (e) {
				return console.log(e)
			}
		},
		// 获取到公司后，以第一项基准查询近四项
		async getDocList(companyId) {
			try {
				this.docList.loading = true
				const { records, total } = await this.$api.know.getDocList({
					companyId,
					pageNumber: 1,
					pageSize: 3,
					isSearchCompany:1
				})
				this.docList.list = records
				console.log(records, 'records')
			} catch (e) {
				return console.log(e)
			} finally {
				this.docList.loading = false
			}
		},
		async docCateList() {
			try {
				const { records, total } = await this.$api.know.docCateList({
					pageNumber: 1,
					pageSize: 2000
				})
				this.s_doclist.options = records
			} catch (e) {
				return console.log(e)
			}
		},
		async getDocListByCollect(type) {
			try {
				this.s_doclist.type = type
				this.s_doclist.loading = true
				this.s_doclist.mapList.set(type,
					this.s_doclist.mapList.get(type) ?? (
						await this.$api.know.getDocListByCollect({ type })
					).records
				)
			} catch (e) {
				return console.log(e)
			} finally {
				this.s_doclist.loading = false
			}
		},
		async getDocListByType(type) {
			try {
				this.s_doclist.type = type
				this.s_doclist.loading = true
				this.s_doclist.mapList.set(type,
					this.s_doclist.mapList.get(type) ?? (
						await this.$api.know.getDocListByType({ type })
					)
				)
				console.log(this.s_doclist.mapList)
			} catch (e) {
				return console.log(e)
			} finally {
				this.s_doclist.loading = false
			}
		},
		async getDocStatusCount() {
			try {
				const result = await this.$api.document.getDocStatusCount()
				this.docStatus = result
				console.log(result, 'result')
			} catch (e) {
				return console.log(e)
			}
		},
	}
}
</script>
<style lang="scss" scoped>
.w300 {
	width: 300px;
}

.colHtext {
	color: #151822;
	transition: .4s;

	&:hover {
		transition: .4s;
		color: #ff6a00;
		cursor: pointer;
	}
}

.m-mainbox {
	.col-l {
		float: left;
		width: 65%;
		margin-right: 16px;
	}

	.col-r {
		overflow: hidden;
	}
}

.m-userbox {
	display: flex;
	align-items: center;
	padding: 15px 0;

	.user {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.name {
		font-size: 19px;
		color: #343434;
		font-weight: 500;
		margin-bottom: 12px;
	}

	.id {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #7b7b7b;

		span {
			color: #343434;
		}

		.el-icon {
			cursor: pointer;
			margin-left: 5px;
			color: #ff6a00;
		}
	}

	.img {
		border-radius: 50%;
		width: 80px;
		height: 80px;
		display: block;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-right: 12px;
	}

	.r {
		flex-shrink: 0;
		overflow: hidden;
	}

	.info {
		font-size: 14px;
		color: #7b7b7b;

		em {
			color: #ff6a00;
			font-style: normal;
		}

		.btn {
			color: #0091ea;
			margin-left: 19px;
		}
	}
}

.ul-listb1 {
	overflow: hidden;
	margin: 0 -8px 14px;

	li {
		float: left;
		width: 33.33%;

		&:nth-child(1) {
			.num {
				color: #337eff;
			}
		}
	}

	.con {
		display: block;
		cursor: pointer;
		//height: 96px;
		background: #f7f7f7;
		border-radius: 5px;
		overflow: hidden;
		margin: 0 8px;
		font-size: 14px;
		color: #676767;
		padding: 14px 10px 10px 10px;
		box-sizing: border-box;
		text-align: center;
	}

	.p {
		margin-bottom: 7px;
	}

	.num {
		font-size: 34px;
		color: #3c3c3c;
		font-weight: 500;
		font-family: Roboto;
	}
}

.ul-listb2 {
	.con {
		cursor: pointer;
		display: block;
		margin-bottom: 24px;

		&:hover {
			.tit {
				color: #337eff;
			}
		}
	}

	.tit {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 14px;
		color: #333;
		transition: 0.4s;
		margin-bottom: 14px;
	}

	.date {
		font-size: 14px;
		color: #808080;
	}
}

.ul-listb3 {
	.con {
		padding: 5px 0;
	}

	.link {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 14px;
		color: #303030;
		transition: 0.4s;
		padding-left: 10px;
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 5px;
			height: 5px;
			background: #989898;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -2px;
			transition: 0.4s;
		}

		&:hover {
			color: #ff6a00;

			&::before {
				background-color: #ff6a00;
			}
		}
	}
}

.m-txtb1 {
	display: flex;
	align-items: center;
	overflow: hidden;

	.txt {
		flex: 1;
		overflow: hidden;
	}

	.h3 {
		font-size: 17px;
		color: #333;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.p {
		font-size: 14px;
		color: #6a6868;
	}

	.img {
		flex-shrink: 0;
		width: 66px;
		margin-left: 10px;

		img {
			display: block;
			width: 100%;
		}
	}
}

.m-chobox {
	min-height: 200px;

	.type {
		font-size: 14px;
		color: #818181;
		padding-bottom: 14px;
	}

	.line {
		margin-bottom: 30px;
	}

	.df {
		display: flex;
		justify-content: space-between;

		.item:nth-child(1) {
			width: 68.5%;
		}

		.item:nth-child(2) {
			width: 27.5%;
		}
	}

	--el-border-radius-base: 0;
}

.ul-hisb1 {
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
		margin-right: 60px;
		margin-bottom: 10px;
	}

	.a {
		cursor: pointer;
		font-size: 14px;
		color: #303030;
		transition: 0.4s;

		&:hover {
			color: #337eff;
		}
	}
}

.severity {
	padding-left: 14px;
	position: relative;
	color: #313133;

	&::before {
		content: "";
		display: block;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -3px;
	}

	&.lev1 {
		&::before {
			background-color: #32e6aa;
		}
	}

	&.lev2 {
		&::before {
			background-color: #06b0ff;
		}
	}

	&.lev3 {
		&::before {
			background-color: #ff9006;
		}
	}

	&.lev4 {
		&::before {
			background-color: #e1504a;
		}
	}
}

.g-sel_inp {
	display: flex;
	align-items: center;
}

.opts {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #888888;
	cursor: pointer;

	.set {
		margin-right: 12px;
	}
}

.m-leftbox,
.m-flexbox {
	display: flex;
	align-items: center;
}

.m-leftbox {
	.item {
		margin-right: 24px;
	}
}

.g-sel_inp {
	display: flex;
	align-items: center;
	--el-border-radius-base: 0;

	.el-select {
		margin-right: -1px;
		width: 110px;
		flex-shrink: 0;
	}

	.el-select__wrapper.is-focused {
		z-index: 2;
	}
}

.m-chotop {
	margin-bottom: 16px;

	.left {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.el-button--primary {
		margin-right: 24px;
	}

	--el-border-radius-base: 0;

	.el-select {
		width: 290px;
	}
}

.doc-btn {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	//height: 65px;
	font-size: 17px;
	color: #fff;
	background-color: #ff6a00;
	border-radius: 5px;
	padding: 0px 10px;
	box-sizing: border-box;
	cursor: pointer;
	transition: 0.4s;
	padding:20px 10px;

	span {
		margin-left: 8px;
	}

	&:hover {
		background-color: #ff8833;
	}
}

.ul-docls {
	overflow: hidden;
	margin: 0 -12px;

	li {
		float: left;
		width: 33.33%;
	}

	.con {
		display: block;
		overflow: hidden;
		margin: 0 12px 24px;
		background: #f7f7f7 url(../../assets/imgs/share.svg) no-repeat 95% center;
		background-size: 12px;
		padding: 0 40px 0 20px;
	}

	.tit {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-size: 14px;
		color: #313133;
		height: 48px;
		line-height: 48px;
		cursor: pointer;
		transition: 0.4s;

		&:hover {
			color: #337eff;
		}
	}
}
.scrboxb1 {
	min-height: 200px
}
@media (min-width: 500px){
	.h3{
		//color:blue;
		font-size: 12px;
	}
	.g-titb1 .more{font-size:12px}
	.m-userbox {
		.user{
			.img{width:30px;height:30px}
			.name{font-size: 10px;}
			.id{font-size: 10px}
		}
		.info{font-size: 10px;}
	}
	.ul-listb1 {
		.con{
			.p{font-size: 12px;}
			.num{font-size: 15px;}
		}
	}
	.g-tabsb1 .con{font-size: 12px}
	.m-chobox .type{font-size:12px}
}
@media (min-width: 1600px){
	.h3{
		//color:red;
		font-size: 20px;
	}
	.g-titb1 .more{font-size:20px}
	.m-userbox {
		.user{
			.img{width:50px;height:50px}
			.name{font-size: 15px}
			.id{font-size: 15px}
		}
		.info{font-size: 15px}
	}
	.ul-listb1 {
		.con{
			.p{font-size: 17px}
			.num{font-size: 20px}
		}
	}
	.g-tabsb1 .con{font-size: 17px}
	.m-chobox .type{font-size:17px}
}
@media (min-width: 2000px){
	.h3{
		//color:green;
		font-size: 25px;
	}
	.g-titb1 .more{font-size:25px}
	.m-userbox {
		.user{
			.img{width:70px;height:70px}
			.name{font-size: 19px;}
			.id{font-size: 19px}
		}
		.info{font-size: 19px;}
	}
	.ul-listb1 {
		.con{
			.p{font-size: 22px}
			.num{font-size: 25px}
		}
	}
	.g-tabsb1 .con{font-size: 22px}
	.m-chobox .type{font-size:22px}
}
</style>